import React from "react";

export default function ReturnIcon() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        // width="512"
        // height="512"
        x="0"
        y="0"
        viewBox="0 0 512 512"
        class=""
      >
        <g>
          <path
            fill="#c38f86"
            d="M343.749 81.765 204.678 1.473a10.993 10.993 0 0 0-10.994 0L54.613 81.765a10.97 10.97 0 0 0-4.328 4.639l4.598 12.274-5.767 11.804v141.39c0 3.928 2.095 7.557 5.497 9.521l139.071 80.293a10.993 10.993 0 0 0 10.994 0l139.071-80.293a10.995 10.995 0 0 0 5.497-9.521V91.286c0-3.927-2.095-7.557-5.497-9.521z"
            opacity="1"
            data-original="#c38f86"
            class=""
          ></path>
          <path
            fill="#b87d72"
            d="M192.871 193.48a12.612 12.612 0 0 0 12.618 0l143.756-82.998V91.286c0-1.727-.426-3.386-1.169-4.882L199.18 172.37 50.285 86.405a10.962 10.962 0 0 0-1.169 4.882v19.196z"
            opacity="1"
            data-original="#b87d72"
            class=""
          ></path>
          <path
            fill="#b87d72"
            d="M285.317 139.278a7.696 7.696 0 0 1-3.856-1.036L125.815 46.004a7.725 7.725 0 0 1-2.828-10.554 7.723 7.723 0 0 1 10.554-2.828l155.645 92.237a7.726 7.726 0 0 1 2.828 10.554 7.717 7.717 0 0 1-6.697 3.865zM199.181 345.393a7.726 7.726 0 0 1-7.726-7.726V181.882a7.726 7.726 0 1 1 15.452 0v155.785a7.726 7.726 0 0 1-7.726 7.726z"
            opacity="1"
            data-original="#b87d72"
            class=""
          ></path>
          <path
            fill="#685e68"
            d="M238.645 275.591a10.963 10.963 0 0 0-5.478 9.492v40.153l78.377-45.251v-39.992c0-.931-.29-1.862-.909-2.558a3.708 3.708 0 0 0-4.644-.769z"
            opacity="1"
            data-original="#685e68"
          ></path>
          <path
            fill="#faf7f7"
            d="M139.46 271.735a7.696 7.696 0 0 1-3.856-1.036l-44.208-25.523a7.726 7.726 0 0 1 7.726-13.382l44.208 25.523a7.726 7.726 0 0 1-3.87 14.418z"
            opacity="1"
            data-original="#faf7f7"
          ></path>
          <path
            fill="#ff7383"
            d="M371.408 301.58a6.046 6.046 0 0 0-6.046 6.046v97.3c0 6.98-5.659 12.639-12.639 12.639h-91.109a4.24 4.24 0 0 1-4.24-4.24v-28.962c0-2.905-3.408-4.472-5.613-2.58l-71.156 61.043a4.811 4.811 0 0 0 0 7.303l71.156 61.044c2.205 1.892 5.613.325 5.613-2.58V479.63a4.24 4.24 0 0 1 4.24-4.24h90.077c38.917 0 70.466-31.549 70.466-70.466v-97.299a6.046 6.046 0 0 0-6.046-6.046h-44.703z"
            opacity="1"
            data-original="#ff7383"
            class=""
          ></path>
          <path
            fill="#ff546a"
            d="M417.141 301.58h-24.104a6.046 6.046 0 0 1 6.046 6.046v97.299c0 38.917-31.549 70.466-70.466 70.466h24.104c38.917 0 70.466-31.549 70.466-70.466v-97.299a6.046 6.046 0 0 0-6.046-6.046z"
            opacity="1"
            data-original="#ff546a"
          ></path>
          <path
            fill="#ff7383"
            d="m310.651 123.09 5.912 7.655 4.933 11.356 26.44 13.596 23.633 8.66 43.443-15.465 31.637-22.683 7.259-38.408-4.99-28.592-8.931-16.757-7.315-9.782-6.399-5.595a78.897 78.897 0 0 0-42.685-12.485c-43.795 0-79.297 35.502-79.297 79.297 0 8.095 1.22 15.904 3.474 23.261z"
            opacity="1"
            data-original="#ff7383"
            class=""
          ></path>
          <path
            fill="#ff546a"
            d="M426.167 26.983c10.94 12.967 17.538 29.717 17.538 48.011 0 41.147-33.356 74.503-74.503 74.503-25.509 0-48.019-12.822-61.448-32.371 9.934 32.455 40.126 56.058 75.834 56.058 43.795 0 79.297-35.503 79.297-79.297-.001-28.123-14.643-52.825-36.718-66.904z"
            opacity="1"
            data-original="#ff546a"
          ></path>
          <path
            fill="#faf7f7"
            d="m394.515 93.888 22.83-22.83a7.729 7.729 0 0 0 0-10.928 7.73 7.73 0 0 0-10.928 0l-22.83 22.83-22.83-22.83a7.73 7.73 0 0 0-10.928 0 7.729 7.729 0 0 0 0 10.928l22.83 22.83-22.83 22.83a7.729 7.729 0 0 0 10.928 10.928l22.83-22.83 22.83 22.83c1.509 1.508 3.487 2.263 5.464 2.263s3.955-.755 5.464-2.263a7.729 7.729 0 0 0 0-10.928z"
            opacity="1"
            data-original="#faf7f7"
          ></path>
        </g>
      </svg>
    </div>
  );
}
