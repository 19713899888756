import React from "react";

export default function ExchangeIcon() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        // width="50"
        // height="50"
        x="0"
        y="0"
        viewBox="0 0 512 512"
      >
        <g>
          <path
            fill="#f6a96c"
            d="M441.45 318.731a2.098 2.098 0 0 1-1.668-.817l-42.743-55.143-3.269-2.585-1 3.221v163.66l1 3.661h72.194a3.11 3.11 0 0 0 3.11-3.11V319.731l-1.265-1z"
            opacity="1"
            data-original="#f6a96c"
          ></path>
          <g fill="#4fc123">
            <path
              d="M400.719 139.917 330.817 99.56c-2.229-1.287-5.016.322-5.016 2.896v18.79a3.344 3.344 0 0 1-3.344 3.344H214.154v36.446h108.303a3.344 3.344 0 0 1 3.344 3.344v18.79c0 2.574 2.787 4.183 5.016 2.896l69.901-40.357c2.23-1.287 2.23-4.505.001-5.792zM169.279 125.858a2.996 2.996 0 0 0 2.442-1.269h-8.047c-1.058 0-1.99.501-2.603 1.269z"
              fill="#4fc123"
              opacity="1"
              data-original="#4fc123"
            ></path>
          </g>
          <g fill="#47a920">
            <path
              d="M169.279 125.858a2.996 2.996 0 0 0 2.442-1.269h-8.047c-1.058 0-1.99.501-2.603 1.269zM247.79 157.692v-29.758a3.344 3.344 0 0 1 3.344-3.344h-36.98v36.446h36.98a3.344 3.344 0 0 1-3.344-3.344z"
              fill="#47a920"
              opacity="1"
              data-original="#47a920"
            ></path>
          </g>
          <path
            fill="#ffbd86"
            d="m76.305 86.289-1-5.017H3.11A3.11 3.11 0 0 0 0 84.382v164.322a3.11 3.11 0 0 0 3.11 3.11h72.194l1-5.202V86.289z"
            opacity="1"
            data-original="#ffbd86"
          ></path>
          <path
            fill="#f6a96c"
            d="M22 248.704V84.382a3.11 3.11 0 0 1 3.11-3.11h-22A3.11 3.11 0 0 0 0 84.382v164.322a3.11 3.11 0 0 0 3.11 3.11h22a3.11 3.11 0 0 1-3.11-3.11z"
            opacity="1"
            data-original="#f6a96c"
          ></path>
          <path
            fill="#fed2a4"
            d="M212.045 81.272h-39.766l-1 1.093v40.493c0 1.103-.897 2-2 2h-48.1c-1.103 0-2-.897-2-2V82.305l-1-1.032H75.304v170.542h136.741a3.11 3.11 0 0 0 3.11-3.11V84.382a3.11 3.11 0 0 0-3.11-3.11z"
            opacity="1"
            data-original="#fed2a4"
          ></path>
          <path
            fill="#fe646f"
            d="M121.18 125.858h48.099a3 3 0 0 0 3-3V81.272H118.18v41.586a3 3 0 0 0 3 3z"
            opacity="1"
            data-original="#fe646f"
          ></path>
          <path
            fill="#fd4755"
            d="M118.18 81.272v40.586a4 4 0 0 0 4 4h22.031V81.272z"
            opacity="1"
            data-original="#fd4755"
          ></path>
          <path
            fill="#ea9b58"
            d="M393.77 260.186v170.542h30.149V299.081z"
            opacity="1"
            data-original="#ea9b58"
          ></path>
          <path
            fill="#fed2a4"
            d="m391.756 261.186-43.068 56.711a2.124 2.124 0 0 1-1.681.834h-90.739l-2.348 1v36.692h80.736a2.347 2.347 0 0 1 2.345 2.344v29.758a2.347 2.347 0 0 1-2.345 2.344H253.92v36.749a3.11 3.11 0 0 0 3.11 3.11h136.74V260.186z"
            opacity="1"
            data-original="#fed2a4"
          ></path>
          <g fill="#ffbd86">
            <path
              d="M253.919 319.731h34.27v36.692h-34.27zM288.19 427.618v-36.749h-34.27v36.749a3.11 3.11 0 0 0 3.11 3.11h34.27a3.11 3.11 0 0 1-3.11-3.11z"
              fill="#ffbd86"
              opacity="1"
              data-original="#ffbd86"
            ></path>
          </g>
          <path
            fill="#c4e2ff"
            d="m393.77 260.186 45.222 58.34a3.11 3.11 0 0 0 2.458 1.205h67.434c2.586 0 4.042-2.972 2.458-5.015l-42.268-54.53z"
            opacity="1"
            data-original="#c4e2ff"
          ></path>
          <path
            fill="#b3dafe"
            d="m473.503 318.526-45.222-58.34H393.77l45.222 58.34a3.11 3.11 0 0 0 2.458 1.205h34.511a3.11 3.11 0 0 1-2.458-1.205z"
            opacity="1"
            data-original="#b3dafe"
          ></path>
          <path
            fill="#c4e2ff"
            d="M347.007 319.731h-132.04c-2.571 0-4.032-2.943-2.477-4.991l41.43-54.554h139.85l-44.286 58.316a3.112 3.112 0 0 1-2.477 1.229z"
            opacity="1"
            data-original="#c4e2ff"
          ></path>
          <path
            fill="#b3dafe"
            d="m256.032 314.74 41.43-54.554H253.92l-41.43 54.554c-1.555 2.048-.095 4.991 2.477 4.991h43.542c-2.572 0-4.032-2.943-2.477-4.991z"
            opacity="1"
            data-original="#b3dafe"
          ></path>
          <path
            fill="#fe646f"
            d="M167.512 330.392 97.611 370.75c-2.229 1.287-2.229 4.505 0 5.792l69.901 40.357c2.229 1.287 5.016-.322 5.016-2.896v-18.79a3.344 3.344 0 0 1 3.344-3.344h158.784a3.344 3.344 0 0 0 3.344-3.344v-29.758a3.344 3.344 0 0 0-3.344-3.344H175.872a3.344 3.344 0 0 1-3.344-3.344v-18.79c0-2.575-2.787-4.184-5.016-2.897z"
            opacity="1"
            data-original="#fe646f"
          ></path>
          <path
            fill="#fd4755"
            d="M116.008 360.128 97.611 370.75c-2.229 1.287-2.229 4.505 0 5.792l69.901 40.357c2.229 1.287 5.016-.322 5.016-2.896v-18.79c0-.773.273-1.475.713-2.042z"
            opacity="1"
            data-original="#fd4755"
          ></path>
        </g>
      </svg>
    </div>
  );
}
