import React, { useState } from "react";
import { Box } from "@mui/material";
import {
  ListItemText,
  List,
  ListItemButton,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import {
  MenuIcon,
  ShipmentIcon,
  PickupsIcon,
  ExportDataIcon,
  TicketsIcon,
  AdvancedSearch,
  NewShipment,
} from "../../assets/icons";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Sidebar({ sideOpen, setSideOpen }) {
  const { t } = useTranslation("layout");
  return (
    <Box
      sx={{
        width: sideOpen ? "200px" : "65px",
        transition: "width .5s",
        display: {
          sm: "none",
          xs: "none",
          md: "inline",
          lg: "inline",
          xl: "inline",
        },
        position: "sticky",
        top: 0,
        height: "100vh",
        color: "white",
        fontWeight: 500,
      }}
    >
      <List
        sx={{ width: "100%" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <Box
          to="/"
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "end",
            position: "relative",
            left: 0,
          }}
        >
          <Box
            sx={{
              width: "35px",
              height: "35px",
              display: "grid",
              placeItems: "center",
              borderRadius: "30px",
              position: "relative",
              "&:hover": {
                background: "#eee",
                color: "#BBB",
                cursor: "pointer",
              },
              left: 5,
              paddingTop: "10px",
            }}
            onClick={() => setSideOpen(!sideOpen)}
          >
            <Box
              component="span"
              sx={{
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "max-content",
                height: "max-content",
              }}
            >
              {sideOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="48"
                  width="48"
                  fill="white"
                  transform="scale(.5)"
                >
                  <path d="M24 40 21.9 37.85 34.25 25.5H8V22.5H34.25L21.9 10.15L24 8L40 24Z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="48"
                  width="48"
                  fill="white"
                  transform="scale(0.5)"
                >
                  <path d="M24 40 8 24 24 8 26.1 10.1 13.7 22.5H40V25.5H13.7L26.1 37.9Z" />
                </svg>
              )}
            </Box>
          </Box>
        </Box>
        <ListItemButton
          component={NavLink}
          to="/"
          sx={{
            textAlign: "right",
            py: 1,
            marginBottom: 2,
          }}
        >
          <ListItemIcon sx={{ minWidth: "35px", marginX: "7px" }}>
            <img src={MenuIcon} alt="" />
          </ListItemIcon>
          <ListItemText primary={t("Sidebar.Menu")} />
        </ListItemButton>
        <ListItemButton
          component={NavLink}
          to="/reports/shipments"
          sx={{
            textAlign: "right",
            gap: "2px",
            py: 2,
          }}
        >
          <ListItemIcon sx={{ minWidth: "35px", marginX: "7px" }}>
            <img src={ShipmentIcon} alt="" />
          </ListItemIcon>
          <ListItemText primary={t("Sidebar.Shipments")} />
        </ListItemButton>
        <ListItemButton
          component={NavLink}
          to="/reports/pickups"
          sx={{
            textAlign: "right",
            gap: "2px",
            py: 2,
          }}
        >
          <ListItemIcon sx={{ minWidth: "35px", marginX: "7px" }}>
            <img src={PickupsIcon} alt="" />
          </ListItemIcon>

          <ListItemText primary={t("Sidebar.Pickups")} />
        </ListItemButton>
        <ListItemButton
          component={NavLink}
          to="/reports/export"
          sx={{
            textAlign: "right",
            gap: "2px",
            py: 2,
          }}
        >
          <ListItemIcon sx={{ minWidth: "35px", marginX: "7px" }}>
            <img src={ExportDataIcon} alt="export data " />
          </ListItemIcon>
          <ListItemText primary={t("Sidebar.Reports_ExportData")} />
        </ListItemButton>
        <ListItemButton
          component={NavLink}
          to="/multi-search"
          sx={{
            textAlign: "right",
            py: 2,
          }}
        >
          <ListItemIcon sx={{ minWidth: "35px", marginX: "7px" }}>
            <img src={AdvancedSearch} alt="" />
          </ListItemIcon>
          <ListItemText primary={t("Sidebar.Advanced_Search")} />
        </ListItemButton>
        <ListItemButton
          component={NavLink}
          to="/tickets"
          sx={{
            textAlign: "right",
            py: 2,
          }}
        >
          <ListItemIcon sx={{ minWidth: "35px", marginX: "7px" }}>
            <img src={TicketsIcon} alt="" />
          </ListItemIcon>
          <ListItemText primary={t("Sidebar.Tickets")} />
        </ListItemButton>
        <ListItemButton
          component={NavLink}
          to="/quick-newshipment"
          sx={{
            textAlign: "right",
            py: 2,
          }}
        >
          <ListItemIcon sx={{ minWidth: "35px", marginX: "7px" }}>
            <img src={NewShipment} alt="" />
          </ListItemIcon>
          <ListItemText primary={t("Sidebar.Quick_NewShipment")} />
        </ListItemButton>
      </List>
    </Box>
  );
}
