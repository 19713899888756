import React from "react";
import styles from "../../assets/css/modules/loadingButton.module.css";
import { useTranslation } from "react-i18next";

export default function LoadingButton() {
  const { i18n } = useTranslation();
  const { language } = i18n;
  return (
    <>
      <div>
        <div
          className={language == "en" ? styles.containerEN : styles.containerAR}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="140px"
            height="140px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <circle
              cx="50"
              cy="50"
              r="32"
              strokeWidth="8"
              stroke="#fff"
              strokeDasharray="50.26548245743669 50.26548245743669"
              fill="none"
              strokeLinecap="round"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                dur="1s"
                repeatCount="indefinite"
                keyTimes="0;1"
                values="0 50 50;360 50 50"
              />
            </circle>
            <circle
              cx="50"
              cy="50"
              r="23"
              strokeWidth="8"
              stroke="#94bffc"
              strokeDasharray="36.12831551628262 36.12831551628262"
              strokeDashoffset="36.12831551628262"
              fill="none"
              strokeLinecap="round"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                dur="1s"
                repeatCount="indefinite"
                keyTimes="0;1"
                values="0 50 50;-360 50 50"
              />
            </circle>
          </svg>
        </div>
      </div>
    </>
  );
}
