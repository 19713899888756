import React from "react";

export default function CashCollectionIcon() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        // width="512"
        // height="512"
        x="0"
        y="0"
        viewBox="0 0 1.707 1.44"
        fillRule="evenodd"
      >
        <g>
          <path
            d="M.385 1.358c-.246 0-.366-.04-.371-.041A.02.02 0 0 1 0 1.297v-.8A.02.02 0 0 1 .026.478c.142.047.45.047.686.018.276-.033.64-.04.868-.019a.02.02 0 0 1 .018.02v.8a.02.02 0 0 1-.022.02 4.612 4.612 0 0 0-.86.02 2.808 2.808 0 0 1-.331.02zM.04 1.283c.17.044.436.042.672.014.276-.033.595-.04.846-.02V.515a4.588 4.588 0 0 0-.841.02C.48.564.217.567.04.524v.759z"
            fill="#000000"
            opacity="1"
            data-original="#000000"
          ></path>
          <path
            d="M.757 1.231H.754A.322.322 0 1 1 .9 1.218l-.144.013zM.799.63a.282.282 0 0 0-.042.562c.044-.005.089-.009.134-.012a.282.282 0 0 0-.092-.55zM1.478.725c-.124 0-.166-.16-.115-.159L1.48.57c.01 0 .019.01.019.02v.115a.02.02 0 0 1-.02.02zM1.384.607a.095.095 0 0 0 .074.076V.61a3.54 3.54 0 0 0-.074-.002zM1.478 1.21l-.116-.003c-.05-.001-.003-.151.116-.151a.02.02 0 0 1 .02.02v.115a.02.02 0 0 1-.02.02zm-.092-.043c.025 0 .05.002.072.003v-.072a.094.094 0 0 0-.072.07zM.234 1.252l-.117-.014a.02.02 0 0 1-.017-.02v-.115c0-.053.17 0 .154.131a.02.02 0 0 1-.02.018zm-.094-.05.075.008a.095.095 0 0 0-.075-.084V1.2zM.12.755A.02.02 0 0 1 .1.735V.62C.1.589.144.603.183.607.218.611.257.606.254.634A.135.135 0 0 1 .12.755zM.14.643v.07A.095.095 0 0 0 .21.65L.14.643z"
            fill="#000000"
            opacity="1"
            data-original="#000000"
          ></path>
          <path
            d="M.799 1.107C.7 1.107.664.979.71.979a.02.02 0 0 1 .02.02c0 .09.136.09.136 0C.867.95.842.942.794.931.751.92.691.906.691.823.691.764.74.716.8.716c.098 0 .135.127.088.127a.02.02 0 0 1-.02-.02C.867.734.73.734.73.823c0 .05.025.057.073.069.043.01.103.024.103.107 0 .06-.049.108-.108.108z"
            fill="#000000"
            opacity="1"
            data-original="#000000"
          ></path>
          <path
            d="M.799.756c-.025 0-.02-.03-.02-.052 0-.027.04-.027.04 0 0 .021.005.052-.02.052zM.799 1.139c-.025 0-.02-.031-.02-.052 0-.027.04-.027.04 0 0 .021.005.052-.02.052z"
            fill="#000000"
            opacity="1"
            data-original="#000000"
          ></path>
          <path
            d="m1.687 1.202-.11-.007a.02.02 0 0 1-.02-.02v-.66a4.588 4.588 0 0 0-.84.02C.494.564.287.565.126.54A.02.02 0 0 1 .109.52V.381A.02.02 0 0 1 .135.362c.001 0 .12.04.358.04C.596.401.706.393.821.38c.276-.033.64-.04.868-.019a.02.02 0 0 1 .018.02v.8a.02.02 0 0 1-.02.02zm-.09-.046.07.004V.4a4.588 4.588 0 0 0-.841.02C.59.448.326.451.149.408v.095C.304.523.5.522.712.496c.276-.033.64-.04.868-.019a.02.02 0 0 1 .018.02v.659z"
            fill="#000000"
            opacity="1"
            data-original="#000000"
          ></path>
          <path
            fill="#63d8a2"
            d="M.794.93C.751.92.691.907.691.824.691.771.73.727.78.718V.704c0-.027.04-.027.04 0v.014c.083.016.112.125.068.125a.02.02 0 0 1-.02-.02C.867.734.73.734.73.823c0 .05.025.057.073.069.043.01.103.024.103.107a.108.108 0 0 1-.088.106c0 .017-.002.034-.02.034-.019 0-.02-.017-.02-.034C.695 1.089.666.979.71.979a.02.02 0 0 1 .02.02c0 .09.136.09.136 0C.867.95.842.942.794.931zm.097.249a.282.282 0 1 0-.134.012c.044-.005.089-.009.134-.012z"
            opacity="1"
            data-original="#63d8a2"
          ></path>
          <path
            fill="#1a9b62"
            d="M1.384.607a.095.095 0 0 0 .074.076V.61a3.659 3.659 0 0 0-.074-.002zM1.458 1.098a.094.094 0 0 0-.072.07c.024 0 .048 0 .072.002v-.072z"
            opacity="1"
            data-original="#1a9b62"
          ></path>
          <path
            fill="#2bb278"
            d="M1.343 1.166c.008-.04.048-.099.115-.109V.724a.142.142 0 0 1-.115-.118 4.735 4.735 0 0 0-.413.01.323.323 0 0 1 .058.557c.118-.007.238-.009.355-.007z"
            opacity="1"
            data-original="#2bb278"
          ></path>
          <path
            fill="#1a9b62"
            d="M.215 1.21a.095.095 0 0 0-.075-.084V1.2l.075.009z"
            opacity="1"
            data-original="#1a9b62"
          ></path>
          <path
            fill="#2bb278"
            d="M.236.512C.376.52.548.515.694.498L.713.496c.276-.033.64-.04.868-.019a.02.02 0 0 1 .018.02v.659l.069.004V.4a4.588 4.588 0 0 0-.841.02C.59.448.326.451.149.408v.095l.087.009z"
            opacity="1"
            data-original="#2bb278"
          ></path>
          <path
            fill="#63d8a2"
            d="M.712.536a2.718 2.718 0 0 1-.385.02A1.376 1.376 0 0 1 .04.524v.759c.17.044.436.042.672.014.276-.033.595-.04.846-.02V.514a4.588 4.588 0 0 0-.841.02L.712.537zM.235.612A2.575 2.575 0 0 0 .782.589c.21-.022.455-.03.674-.02l.023.001c.01 0 .019.01.019.02v.601a.02.02 0 0 1-.021.02h-.003l-.112-.004H1.36a4.946 4.946 0 0 0-.523.017l-.06.005a2.71 2.71 0 0 1-.39.028c-.083 0-.185-.005-.27-.019a.02.02 0 0 1-.016-.02V.62C.1.589.144.603.183.607.201.61.221.61.235.612z"
            opacity="1"
            data-original="#63d8a2"
          ></path>
          <path
            fill="#2bb278"
            d="M.613.647C.491.657.37.66.251.653a.134.134 0 0 1-.11.1v.33c.044.008.112.053.114.13.132.01.271.003.404-.01A.324.324 0 0 1 .613.646z"
            opacity="1"
            data-original="#2bb278"
          ></path>
          <path
            fill="#1a9b62"
            d="M.14.713A.095.095 0 0 0 .21.65L.14.643v.07z"
            opacity="1"
            data-original="#1a9b62"
          ></path>
          <path
            d="M.386 1.258c-.083 0-.185-.006-.27-.02a.02.02 0 0 1-.016-.02V.62C.1.589.144.603.183.607c.173.018.358.01.541-.012.23-.027.511-.038.755-.025.01 0 .019.01.019.02v.6a.02.02 0 0 1-.021.02 4.815 4.815 0 0 0-.772.027 2.71 2.71 0 0 1-.32.02zM.14 1.2c.177.026.376.018.56-.004.24-.028.51-.038.758-.027V.61a4.6 4.6 0 0 0-.73.025C.529.66.333.666.14.643V1.2z"
            fill="#000000"
            opacity="1"
            data-original="#000000"
          ></path>
        </g>
      </svg>
    </div>
  );
}
