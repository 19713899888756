import axios from "axios";
import i18next from "./i18n";
import store from "../redux/store";
import { logOut } from "../redux/reducers/authReducer";
export default function axiosInstance() {
  const CompanyData = JSON.parse(localStorage.getItem("CompanyData"));
  const AuthedUser = JSON.parse(localStorage.getItem("AuthedUser"));
  const { language } = i18next;
  let instance;
  if (CompanyData) {
    const { APIHost, APIPath } = CompanyData;
    const baseURL = `${APIHost}${APIPath}api/ClientUsers/V6`;
    const AccessToken = AuthedUser?.AccessToken;
    const { ClientID } = CompanyData;
    instance = axios.create({
      baseURL,
      headers: {
        AccessToken: AccessToken,
        "Accept-Encoding": "gzip, deflate, br",
        ApplicationID: "131352",
        CompanyID: ClientID,
        Language: language,
      },
    });
    instance.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        if (error.response.status === 401) {
          store.dispatch(logOut());
          localStorage.removeItem("AuthedUser");
        }
        // Usage
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );
    return instance;
  } else {
    instance = axios;
    return instance;
  }
}
